import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import asFormGroupField from 'modules/forms/components/asFormGroupField';
import iso8601PropType from '@thrivetrm/ui/propTypes/iso8601PropType';
import { MonthSelect } from './MonthSelectField';
import { YearSelect } from './YearSelectField';

/**
 * A component that displays a month selector and a year selector.
 */

const DateTypes = { MONTH: 'month', YEAR: 'year' };

const MonthYearSelect = ({
  className,
  onChange,
  value: previousValue,
  ...props
}) => {
  const momentValue = moment(previousValue, moment.ISO_8601);

  const handleDateChange = (newValue, dateType) => {
    const didClearValue = !Number.isFinite(newValue);
    if (didClearValue) {
      onChange(null);
      return;
    }

    // We have to manage two inputs (month and year) that represent a single date.
    // So, anytime a month or year updates, we take the existing date and modify the month or year.
    // If there is no existing date, we fall back to a date of "today" and then update the month or
    // year with the user's selection (to ensure that we don't wind up with a blank month or year).
    const previousDateOrToday = previousValue
      ? moment(previousValue, moment.ISO_8601)
      : moment();
    if (dateType === DateTypes.MONTH) {
      previousDateOrToday.month(newValue);
    } else {
      previousDateOrToday.year(newValue);
    }

    onChange(previousDateOrToday);
  };

  return (
    <div
      className={classnames(
        'forms--month-year-select row no-gutters',
        className,
      )}
    >
      <MonthSelect
        className='forms--month-year-select-month col-7 u-paddingRight-8'
        key='month'
        onChange={newValue => handleDateChange(newValue, DateTypes.MONTH)}
        value={momentValue.month()}
      />
      <YearSelect
        {...props}
        className='forms--month-year-select-year col-5'
        key='year'
        onChange={newValue => handleDateChange(newValue, DateTypes.YEAR)}
        value={momentValue.year()}
      />
    </div>
  );
};

MonthYearSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: iso8601PropType,
};

export default asFormGroupField(MonthYearSelect);

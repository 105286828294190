import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import withPropsRemoved from 'modules/core/componentsLegacy/withPropsRemoved';
import * as stringUtils from '@thrivetrm/ui/utilities/stringUtils';
import routes from 'modules/routing/routes';
import { useApiGet } from 'modules/core/hooks/useApi';
import withFeatureCheck from 'modules/auth/components/withFeatureCheck';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import Form from 'modules/forms/components/Form';
import FormErrorMessage from 'modules/forms/components/FormErrorMessage';
import SubmitButton from 'modules/forms/components/SubmitButton';
import formStatePropType from 'modules/forms/propTypes/formStatePropType';
import withContactForm from '../withContactForm';
import CandidatePortalConsentField from './CandidatePortalConsentField';

/**
 * A form candidate users to consent to using the Candidate Portal
 */
const CandidatePortalConsentForm = ({
  formState,
  hasCaslEnabled,
  onFieldStateChange,
  onSubmit,
  tenantName,
  ...fieldProps
}) => {
  const [
    loadCustomersConfiguration,
    _isLoadingCustomersConfiguration,
    _customersConfigurationLoadError,
    customersConfiguration,
  ] = useApiGet(routes.api_v1_customers_configuration());

  useEffect(() => {
    loadCustomersConfiguration();
  }, [loadCustomersConfiguration]);

  const formatCompanyAddressAttributes = attributes =>
    attributes
      .map(
        attribute =>
          customersConfiguration?.customer_configuration &&
          customersConfiguration?.customer_configuration?.address &&
          customersConfiguration?.customer_configuration?.address[attribute],
      )
      .filter(attribute => Boolean(attribute))
      .join(' | ');

  const customerAddress = formatCompanyAddressAttributes([
    'address_line_1',
    'address_line_2',
    'city',
    'state',
    'postal_code',
  ]);

  const companyContactInfo = `${customersConfiguration?.customer_configuration?.website_url} |
        ${customersConfiguration?.customer_configuration?.phone}`;
  return (
    <div className='CandidatePortalConsentForm'>
      {customersConfiguration?.customer_configuration && (
        <p className='CandidatePortalConsentForm__privacyPolicy'>
          Please review {stringUtils.possessivize(tenantName)} privacy policy at{' '}
          <a
            href={
              customersConfiguration.customer_configuration.privacy_policy_url
            }
            rel='noopener noreferrer'
            target='_blank'
          >
            {customersConfiguration.customer_configuration.privacy_policy_url}
          </a>
          .
        </p>
      )}
      <Form formState={formState} onSubmit={onSubmit}>
        <CandidatePortalConsentField
          {...fieldProps}
          disabled={formState.isSubmitting()}
          fieldState={formState.getFieldState()}
          hasCaslEnabled={hasCaslEnabled}
          onChange={onFieldStateChange}
          showErrors={formState.wasSubmitted() || 'blurred'}
        />
        <FormErrorMessage formState={formState} />
        <div className='row'>
          <div className='col-sm-4'>
            <SubmitButton formState={formState} onClick={onSubmit}>
              Submit
            </SubmitButton>
          </div>
          {hasCaslEnabled && customersConfiguration && (
            <div className='u-textAlign-r col-sm-8'>
              <strong className='pull-right'>
                {customerAddress}
                <br /> {companyContactInfo}
              </strong>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

CandidatePortalConsentForm.propTypes = {
  customerConfiguration: ImmutablePropTypes.mapContains({
    consent_and_privacy_policy_url: PropTypes.string,
    company_name: PropTypes.string,
    address_one: PropTypes.string,
    address_two: PropTypes.string,
    city: PropTypes.string,
    state_province: PropTypes.string,
    company_url: PropTypes.string,
    phone: PropTypes.string,
  }),

  /**
   * The current FormState of the form.
   */
  formState: formStatePropType,

  /**
   * True if the casl flipper is configuration; otherwise, false.
   */
  hasCaslEnabled: PropTypes.bool,

  /**
   * Called when the cancel button is clicked.
   */
  onCancel: PropTypes.func,

  /**
   * Called when the formState's underlying fieldState should be changed.
   */
  onFieldStateChange: PropTypes.func,

  /**
   * Called when the form should be submitted.
   */
  onSubmit: PropTypes.func,

  /**
   * The name of the current tenant.
   */
  tenantName: PropTypes.string,
};

/**
 * The connected version of this component includes a FormState/FieldState and
 * handlers for submitting the form.
 */
export default compose(
  setDisplayName('CandidatePortalConsentForm(enhanced))'),
  setPropTypes({
    /**
     * The ID of the contact for which the internal notes will be edited.
     */
    contactId: PropTypes.number,

    /**
     * Called when the cancel button on the form is clicked.
     */
    onCancel: PropTypes.func,

    /**
     * Called when the form has been successfully saved.
     */
    onSaved: PropTypes.func,
  }),
  withFeatureCheck('feature.casl', 'hasCaslEnabled'),

  withStateHandlers(
    { isRedirecting: false },
    {
      onSaved: (_, { contactId }) => () => {
        if (contactId) {
          window.location = routes.contact({ id: contactId });
          return { isRedirecting: true };
        }
        return {};
      },
    },
  ),

  // Provides the field state, form state, and submission handling.
  withContactForm(CandidatePortalConsentField),
  /**
   * Prevent props from being passed on to the underlying fieldState
   */
  withPropsRemoved(
    'contactId',
    'isRedirecting',
    'onFormStateChange',
    'onResetFormState',
    'onSaved',
  ),
)(CandidatePortalConsentForm);
